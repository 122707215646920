<template>
  <MTModule v-if="this.$store.getters.isMobile"></MTModule>
  <PCModule v-else></PCModule>
</template>

<script>
import PCModule from "./PC";
import MTModule from "./MT";

export default {
  name: "Home",
  components: {
    PCModule,
    MTModule,
  },
  props: {
  },
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {
  }
};
</script>
<style scoped></style>